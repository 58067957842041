import React from 'react'
import { Auth } from 'aws-amplify'
// import Auth from '@aws-amplify/auth'
import { setUser } from './src/services/auth'
// import { CurrencyProvider } from "./src/context/CurrencyContext"
import { SettingsProvider } from "./src/context/SettingsContext"
// const React = require('react')
// const Layout = require('./src/components/Layout').default
//
// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username
      }
      setUser(userInfo)
      // console.log(user.username)
    })
    .catch(err => {
      window.localStorage.setItem('menuiUser', null)
      console.log(err)
    })
}

// export const wrapRootElement = ({ element }) => (
//     <CurrencyProvider>{element}</CurrencyProvider>
// )

export const wrapPageElement = ({ element }) => (
  <SettingsProvider>{element}</SettingsProvider>
)


