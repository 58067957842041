/* API FUNCTION CALLS */

const authenticate = (data) => {
  return fetch('/.netlify/functions/authentication', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createCustomers = (data) => {
  return fetch('/.netlify/functions/customer-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createSubscription = (data) => {
  return fetch('/.netlify/functions/subscription-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const loadCustomer = (keyword) => {
  return fetch(`/.netlify/functions/customer-load/${keyword}`).then((response) => {
    return response.json()
  })
}

const createDisabledDeliveryDay = (data) => {
  return fetch('/.netlify/functions/disabled-delivery-day-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createDisabledTakeawayDay = (data) => {
  return fetch('/.netlify/functions/disabled-takeaway-day-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createDisabledBookingDay = (data) => {
  return fetch('/.netlify/functions/disabled-booking-day-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createTable = (data) => {
  return fetch('/.netlify/functions/table-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createArticle = (data) => {
  return fetch('/.netlify/functions/article-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createCategory = (data) => {
  return fetch('/.netlify/functions/category-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createSubcategory = (data) => {
  return fetch('/.netlify/functions/subcategory-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createTakeAwayOrders = (data) => {
  return fetch('/.netlify/functions/takeawayorders-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createDeliveryOrders = (data) => {
  return fetch('/.netlify/functions/deliveryorders-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createLocallyOrders = (data) => {
  return fetch('/.netlify/functions/locallyorders-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createBooking = (data) => {
  return fetch('/.netlify/functions/booking-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createRingStaffStatistic = (data) => {
  return fetch('/.netlify/functions/ring-staff-statistic-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createLocallyOrderStatistic = (data) => {
  return fetch('/.netlify/functions/locallyorder-statistic-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createTakeAwayOrderStatistic = (data) => {
  return fetch('/.netlify/functions/takeawayorder-statistic-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createDeliveryOrderStatistic = (data) => {
  return fetch('/.netlify/functions/deliveryorder-statistic-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const readLocallyStatisticsByDate = (date) => {
  return fetch(`/.netlify/functions/locallyorder-statistics-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readLocallyStatisticsByMonthYear = (monthYear) => {
  return fetch(`/.netlify/functions/locallyorder-statistics-by-month-year/${monthYear}`).then((response) => {
    return response.json()
  })
}

const readLocallyStatisticsByYear = (year) => {
  return fetch(`/.netlify/functions/locallyorder-statistics-by-year/${year}`).then((response) => {
    return response.json()
  })
}


// const readAllTakeAwayOrderStatistics = () => {
//   return fetch('/.netlify/functions/takeawayorder-statistics-read-all').then((response) => {
//     return response.json()
//   })
// }

const readTakeAwayStatisticsByDate = (date) => {
  return fetch(`/.netlify/functions/takeawayorder-statistics-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readTakeAwayStatisticsByMonthYear = (monthYear) => {
  return fetch(`/.netlify/functions/takeawayorder-statistics-by-month-year/${monthYear}`).then((response) => {
    return response.json()
  })
}

const readTakeAwayStatisticsByYear = (year) => {
  return fetch(`/.netlify/functions/takeawayorder-statistics-by-year/${year}`).then((response) => {
    return response.json()
  })
}

const readDeliveryStatisticsByDate = (date) => {
  return fetch(`/.netlify/functions/deliveryorder-statistics-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readDeliveryStatisticsByMonthYear = (monthYear) => {
  return fetch(`/.netlify/functions/deliveryorder-statistics-by-month-year/${monthYear}`).then((response) => {
    return response.json()
  })
}

const readDeliveryStatisticsByYear = (year) => {
  return fetch(`/.netlify/functions/deliveryorder-statistics-by-year/${year}`).then((response) => {
    return response.json()
  })
}

const readRingStaffStatisticsByDate = (date) => {
  return fetch(`/.netlify/functions/ring-staff-statistics-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readRingStaffStatisticsByMonthYear = (monthYear) => {
  return fetch(`/.netlify/functions/ring-staff-statistics-by-month-year/${monthYear}`).then((response) => {
    return response.json()
  })
}

const readRingStaffStatisticsByYear = (year) => {
  return fetch(`/.netlify/functions/ring-staff-statistics-by-year/${year}`).then((response) => {
    return response.json()
  })
}

const readBookingStatisticsByDate = (date) => {
  return fetch(`/.netlify/functions/booking-statistics-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readBookingStatisticsByMonthYear = (monthYear) => {
  return fetch(`/.netlify/functions/booking-statistics-by-month-year/${monthYear}`).then((response) => {
    return response.json()
  })
}

const readBookingStatisticsByYear = (year) => {
  return fetch(`/.netlify/functions/booking-statistics-by-year/${year}`).then((response) => {
    return response.json()
  })
}


const readAllCustomers = () => {
  return fetch('/.netlify/functions/customers-read-all').then((response) => {
    return response.json()
  })
}

const readAllSubscriptions = () => {
  return fetch('/.netlify/functions/subscriptions-read-all').then((response) => {
    return response.json()
  })
}

const readTakeAwayTimes = () => {
  return fetch('/.netlify/functions/read-takeawaytimes').then((response) => {
    return response.json()
  })
}

const readBookingTimes = () => {
  return fetch('/.netlify/functions/read-bookingtimes').then((response) => {
    return response.json()
  })
}

// const readReservedBookings = () => {
//   return fetch('/.netlify/functions/read-reserved-bookings').then((response) => {
//     return response.json()
//   })
// }

const readReservedBookings = (date) => {
  return fetch(`/.netlify/functions/read-reserved-bookings/${date}`).then((response) => {
    return response.json()
  })
}

const readDeliveryTimes = () => {
  return fetch('/.netlify/functions/read-deliverytimes').then((response) => {
    return response.json()
  })
}

const readDisabledDeliveryDays = () => {
  return fetch('/.netlify/functions/read-disabled-delivery-days').then((response) => {
    return response.json()
  })
}

const readDisabledTakeawayDays = () => {
  return fetch('/.netlify/functions/read-disabled-takeaway-days').then((response) => {
    return response.json()
  })
}

const readDisabledBookingDays = () => {
  return fetch('/.netlify/functions/read-disabled-booking-days').then((response) => {
    return response.json()
  })
}

const readAllTables = () => {
  return fetch('/.netlify/functions/tables-read-all').then((response) => {
    return response.json()
  })
}

const readRingStaffByDate = (date) => {
  return fetch(`/.netlify/functions/ring-staff-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readAllArticles = () => {
  return fetch('/.netlify/functions/articles-read-all').then((response) => {
    return response.json()
  })
}

const readArticlesByLanguage = (lang) => {
  return fetch(`/.netlify/functions/articles-by-language-read/${lang}`).then((response) => {
    return response.json()
  })
}

const readLatestArticles = () => {
  return fetch('/.netlify/functions/articles-read-latest').then((response) => {
    return response.json()
  })
}

const readLatestCustomers = () => {
  return fetch('/.netlify/functions/customers-read-latest').then((response) => {
    return response.json()
  })
}

const readLatestBookings = () => {
  return fetch('/.netlify/functions/bookings-read-latest').then((response) => {
    return response.json()
  })
}

const readMoreArticles = (lastRef) => {
  return fetch(`/.netlify/functions/articles-read-more/${lastRef}`).then((response) => {
    return response.json()
  })
}

const readMoreCustomers = (lastRef) => {
  return fetch(`/.netlify/functions/customers-read-more/${lastRef}`).then((response) => {
    return response.json()
  })
}

const readMoreBookings = (lastRef) => {
  return fetch(`/.netlify/functions/bookings-read-more/${lastRef}`).then((response) => {
    return response.json()
  })
}

const readArticleDetails = (articleId) => {
  return fetch(`/.netlify/functions/article-details-read/${articleId}`).then((response) => {
    return response.json()
  })
}

const readTakeAwayOrderDetails = (orderId) => {
  return fetch(`/.netlify/functions/takeawayorder-details-read/${orderId}`).then((response) => {
    return response.json()
  })
}

const readDeliveryOrderDetails = (orderId) => {
  return fetch(`/.netlify/functions/deliveryorder-details-read/${orderId}`).then((response) => {
    return response.json()
  })
}

const readLocallyOrderDetails = (orderId) => {
  return fetch(`/.netlify/functions/locallyorder-details-read/${orderId}`).then((response) => {
    return response.json()
  })
}

const readBookingDetails = (bookingId) => {
  return fetch(`/.netlify/functions/booking-details-read/${bookingId}`).then((response) => {
    return response.json()
  })
}

const readCustomerDetails = (customerId) => {
  return fetch(`/.netlify/functions/customer-details-read/${customerId}`).then((response) => {
    return response.json()
  })
}

const readArticleSearch = (keyword) => {
  return fetch(`/.netlify/functions/article-search/${keyword}`).then((response) => {
    return response.json()
  })
}

// const readArticleSearch = () => {
//   return fetch('/.netlify/functions/article-search').then((response) => {
//     return response.json()
//   })
// }

const readCustomerSearchByFirstName = (keyword) => {
  return fetch(`/.netlify/functions/customer-search-by-firstname/${keyword}`).then((response) => {
    return response.json()
  })
}

const readCustomerSearchBySecondName = (keyword) => {
  return fetch(`/.netlify/functions/customer-search-by-secondname/${keyword}`).then((response) => {
    return response.json()
  })
}

const readBookingsByDate = (date) => {
  return fetch(`/.netlify/functions/bookings-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readBookingsByEmail = (email) => {
  return fetch(`/.netlify/functions/bookings-by-email/${email}`).then((response) => {
    return response.json()
  })
}

const readBookingsById = (bookingId) => {
  return fetch(`/.netlify/functions/bookings-by-id/${bookingId}`).then((response) => {
    return response.json()
  })
}

// const readBookingSearch = (keyword) => {
//   return fetch(`/.netlify/functions/booking-search/${keyword}`).then((response) => {
//     return response.json()
//   })
// }

const readAllCategories = () => {
  return fetch('/.netlify/functions/categories-read-all').then((response) => {
    return response.json()
  })
}

const readCategoriesByLanguage = (lang) => {
  return fetch(`/.netlify/functions/categories-by-language-read/${lang}`).then((response) => {
    return response.json()
  })
}

const readCategoryDetails = (id) => {
  return fetch(`/.netlify/functions/category-details-read/${id}`).then((response) => {
    return response.json()
  })
}

const readAllSubcategories = () => {
  return fetch('/.netlify/functions/subcategories-read-all').then((response) => {
    return response.json()
  })
}

const readSubcategoryDetails = (id) => {
  return fetch(`/.netlify/functions/subcategory-details-read/${id}`).then((response) => {
    return response.json()
  })
}

const readSubcategoriesByLanguage = (lang) => {
  return fetch(`/.netlify/functions/subcategories-by-language-read/${lang}`).then((response) => {
    return response.json()
  })
}

const readAllBookings = () => {
  return fetch('/.netlify/functions/bookings-read-all').then((response) => {
    return response.json()
  })
}

const readAllTakeAwayOrders = () => {
  return fetch('/.netlify/functions/takeawayorders-read-all').then((response) => {
    return response.json()
  })
}

const readAllDeliveryOrders = () => {
  return fetch('/.netlify/functions/deliveryorders-read-all').then((response) => {
    return response.json()
  })
}

const readTakeAwayOrdersByDate = (date) => {
  return fetch(`/.netlify/functions/takeawayorders-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readTakeAwayOrdersByOrderNo = (orderNo) => {
  return fetch(`/.netlify/functions/takeawayorders-by-orderno/${orderNo}`).then((response) => {
    return response.json()
  })
}

const readDeliveryOrdersByDate = (date) => {
  return fetch(`/.netlify/functions/deliveryorders-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readDeliveryOrdersByOrderNo = (orderNo) => {
  return fetch(`/.netlify/functions/deliveryorders-by-orderno/${orderNo}`).then((response) => {
    return response.json()
  })
}

const readAllDeliverySettings = () => {
  return fetch('/.netlify/functions/deliverysettings-read-all').then((response) => {
    return response.json()
  })
}

const readAllLocallyOrders = () => {
  return fetch('/.netlify/functions/locallyorders-read-all').then((response) => {
    return response.json()
  })
}

const readLocallyOrdersByDate = (date) => {
  return fetch(`/.netlify/functions/locallyorders-by-date/${date}`).then((response) => {
    return response.json()
  })
}

const readLocallyOrdersByOrderNo = (orderNo) => {
  return fetch(`/.netlify/functions/locallyorders-by-orderno/${orderNo}`).then((response) => {
    return response.json()
  })
}

const readAllSettings = () => {
  return fetch('/.netlify/functions/settings-read-all').then((response) => {
    return response.json()
  })
}

const readAllPlans = () => {
  return fetch('/.netlify/functions/plans-read-all').then((response) => {
    return response.json()
  })
}

const updateCustomer = (customerId, data) => {
  return fetch(`/.netlify/functions/customer-update/${customerId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateSubscription = (subscriptionId, data) => {
  return fetch(`/.netlify/functions/subscription-update/${subscriptionId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateTable = (tableId, data) => {
  return fetch(`/.netlify/functions/table-update/${tableId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateStatistic = (statisticId, data) => {
  return fetch(`/.netlify/functions/statistics-update/${statisticId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateRingStaff = (ringStaffId, data) => {
  return fetch(`/.netlify/functions/ring-staff-update/${ringStaffId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateArticle = (articleId, data) => {
  return fetch(`/.netlify/functions/article-update/${articleId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateCategory = (categoryId, data) => {
  return fetch(`/.netlify/functions/category-update/${categoryId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateSubcategory = (subcategoryId, data) => {
  return fetch(`/.netlify/functions/subcategory-update/${subcategoryId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateTakeAwayOrder = (takeAwayOrderId, data) => {
  return fetch(`/.netlify/functions/takeawayorders-update/${takeAwayOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateDeliveryOrder = (deliveryOrderId, data) => {
  return fetch(`/.netlify/functions/deliveryorders-update/${deliveryOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateDeliverySettings = (deliverySettingsId, data) => {
  return fetch(`/.netlify/functions/deliverysettings-update/${deliverySettingsId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateTakeAwayTime = (takeAwayTimeId, data) => {
  return fetch(`/.netlify/functions/takeawaytimes-update/${takeAwayTimeId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateBookingTime = (bookingTimeId, data) => {
  return fetch(`/.netlify/functions/bookingtimes-update/${bookingTimeId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateDeliveryTime = (deliveryTimeId, data) => {
  return fetch(`/.netlify/functions/deliverytimes-update/${deliveryTimeId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateTakeawayTime = (takeAwayTimeId, data) => {
  return fetch(`/.netlify/functions/takeawaytimes-update/${takeAwayTimeId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateLocallyOrder = (locallyOrderId, data) => {
  return fetch(`/.netlify/functions/locallyorders-update/${locallyOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateBooking = (bookingId, data) => {
  return fetch(`/.netlify/functions/bookings-update/${bookingId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateSetting = (settingId, data) => {
  return fetch(`/.netlify/functions/settings-update/${settingId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteCustomer = (customerId) => {
  return fetch(`/.netlify/functions/customer-delete/${customerId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteTable = (tableId) => {
  return fetch(`/.netlify/functions/table-delete/${tableId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteRingStaff = (ringStaffId) => {
  return fetch(`/.netlify/functions/ring-staff-delete/${ringStaffId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteAllRingStaffEntries = () => {
  return fetch('/.netlify/functions/ring-staff-delete-all').then((response) => {
    return response.json()
  })
}

const deleteArticle = (articleId) => {
  return fetch(`/.netlify/functions/article-delete/${articleId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteCategory = (categoryId) => {
  return fetch(`/.netlify/functions/category-delete/${categoryId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteSubcategory = (subcategoryId) => {
  return fetch(`/.netlify/functions/subcategory-delete/${subcategoryId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteBooking = (bookingId) => {
  return fetch(`/.netlify/functions/bookings-delete/${bookingId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteAllBookings = () => {
  return fetch('/.netlify/functions/bookings-delete-all').then((response) => {
    return response.json()
  })
}

const deleteTakeAwayOrder = (takeAwayOrderId) => {
  return fetch(`/.netlify/functions/takeawayorders-delete/${takeAwayOrderId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteAllTakeAwayOrders = () => {
  return fetch('/.netlify/functions/takeawayorders-delete-all').then((response) => {
    return response.json()
  })
}

const deleteDeliveryOrder = (deliveryOrderId) => {
  return fetch(`/.netlify/functions/deliveryorders-delete/${deliveryOrderId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteAllDeliveryOrders = () => {
  return fetch('/.netlify/functions/deliveryorders-delete-all').then((response) => {
    return response.json()
  })
}

const deleteDisabledDeliveryDay = (disabledDeliveryDayId) => {
  return fetch(`/.netlify/functions/disabled-delivery-days-delete/${disabledDeliveryDayId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteDisabledTakeawayDay = (disabledTakeawayDayId) => {
  return fetch(`/.netlify/functions/disabled-takeaway-days-delete/${disabledTakeawayDayId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteDisabledBookingDay = (disabledBookingDayId) => {
  return fetch(`/.netlify/functions/disabled-booking-days-delete/${disabledBookingDayId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteLocallyOrder = (locallyOrderId) => {
  return fetch(`/.netlify/functions/locallyorders-delete/${locallyOrderId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteAllLocallyOrders = () => {
  return fetch('/.netlify/functions/locallyorders-delete-all').then((response) => {
    return response.json()
  })
}

const resetLocallyOrderStatistics = () => {
  return fetch('/.netlify/functions/locallyorder-statistics-delete-all').then((response) => {
    return response.json()
  })
}

const resetTakeAwayOrderStatistics = () => {
  return fetch('/.netlify/functions/takeawayorder-statistics-delete-all').then((response) => {
    return response.json()
  })
}

const resetDeliveryOrderStatistics = () => {
  return fetch('/.netlify/functions/deliveryorder-statistics-delete-all').then((response) => {
    return response.json()
  })
}

const resetRingStaffStatistics = () => {
  return fetch('/.netlify/functions/ring-staff-statistics-delete-all').then((response) => {
    return response.json()
  })
}

const deleteOldTakeAwayOrders = (takeAwayIds) => {
  return fetch(`/.netlify/functions/takeawayorders-delete-old`, {
    body: JSON.stringify({
      ids: takeAwayIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteOldRingStaff = (ringStaffIds) => {
  return fetch(`/.netlify/functions/ring-staff-delete-old`, {
    body: JSON.stringify({
      ids: ringStaffIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteOldBookings = (bookingIds) => {
  return fetch(`/.netlify/functions/bookings-delete-old`, {
    body: JSON.stringify({
      ids: bookingIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteOldDeliveryOrders = (deliveryIds) => {
  return fetch(`/.netlify/functions/deliveryorders-delete-old`, {
    body: JSON.stringify({
      ids: deliveryIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteOldLocallyOrders = (locallyIds) => {
  return fetch(`/.netlify/functions/locallyorders-delete-old`, {
    body: JSON.stringify({
      ids: locallyIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const batchDeleteCustomer = (customerIds) => {
  return fetch(`/.netlify/functions/customers-delete-batch`, {
    body: JSON.stringify({
      ids: customerIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

// const batchDeleteBooking = (bookingIds) => {
//   return fetch(`/.netlify/functions/bookings-delete-batch`, {
//     body: JSON.stringify({
//       ids: bookingIds
//     }),
//     method: 'POST'
//   }).then(response => {
//     return response.json()
//   })
// }

const batchDeleteTakeAwayOrder = (takeAwayOrderIds) => {
  return fetch(`/.netlify/functions/takeawayorders-delete-batch`, {
    body: JSON.stringify({
      ids: takeAwayOrderIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const batchDeleteDeliveryOrder = (deliveryOrderIds) => {
  return fetch(`/.netlify/functions/deliveryorders-delete-batch`, {
    body: JSON.stringify({
      ids: deliveryOrderIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const batchDeleteLocallyOrder = (locallyOrderIds) => {
  return fetch(`/.netlify/functions/locallyorders-delete-batch`, {
    body: JSON.stringify({
      ids: locallyOrderIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}
export default {
  authenticate: authenticate,
  readTakeAwayTimes: readTakeAwayTimes,
  readDeliveryTimes: readDeliveryTimes,
  readBookingTimes: readBookingTimes,
  createCustomers: createCustomers,
  createSubscription: createSubscription,
  createTable: createTable,
  createArticle: createArticle,
  createCategory: createCategory,
  createSubcategory: createSubcategory,
  createTakeAwayOrders: createTakeAwayOrders,
  createDeliveryOrders: createDeliveryOrders,
  createLocallyOrders: createLocallyOrders,
  createBooking: createBooking,
  createDisabledDeliveryDay: createDisabledDeliveryDay,
  createDisabledTakeawayDay: createDisabledTakeawayDay,
  createDisabledBookingDay: createDisabledBookingDay,
  createRingStaffStatistic: createRingStaffStatistic,
  createLocallyOrderStatistic: createLocallyOrderStatistic,
  createTakeAwayOrderStatistic: createTakeAwayOrderStatistic,
  createDeliveryOrderStatistic: createDeliveryOrderStatistic,
  readLocallyStatisticsByDate: readLocallyStatisticsByDate,
  readLocallyStatisticsByMonthYear: readLocallyStatisticsByMonthYear,
  readLocallyStatisticsByYear: readLocallyStatisticsByYear,
  readTakeAwayStatisticsByDate: readTakeAwayStatisticsByDate,
  readTakeAwayStatisticsByMonthYear: readTakeAwayStatisticsByMonthYear,
  readTakeAwayStatisticsByYear: readTakeAwayStatisticsByYear,
  readDeliveryStatisticsByDate: readDeliveryStatisticsByDate,
  readDeliveryStatisticsByMonthYear: readDeliveryStatisticsByMonthYear,
  readDeliveryStatisticsByYear: readDeliveryStatisticsByYear,
  readRingStaffStatisticsByDate: readRingStaffStatisticsByDate,
  readRingStaffStatisticsByMonthYear: readRingStaffStatisticsByMonthYear,
  readRingStaffStatisticsByYear: readRingStaffStatisticsByYear,
  readBookingStatisticsByDate: readBookingStatisticsByDate,
  readBookingStatisticsByMonthYear: readBookingStatisticsByMonthYear,
  readBookingStatisticsByYear: readBookingStatisticsByYear,
  readAllCustomers: readAllCustomers,
  readAllSubscriptions: readAllSubscriptions,
  readAllTables: readAllTables,
  readRingStaffByDate: readRingStaffByDate,
  readAllArticles: readAllArticles,
  readArticlesByLanguage: readArticlesByLanguage,
  readLatestArticles: readLatestArticles,
  readLatestCustomers: readLatestCustomers,
  readLatestBookings: readLatestBookings,
  readMoreArticles: readMoreArticles,
  readMoreCustomers: readMoreCustomers,
  readMoreBookings: readMoreBookings,
  readArticleDetails: readArticleDetails,
  readCustomerDetails: readCustomerDetails,
  readBookingDetails: readBookingDetails,
  readTakeAwayOrderDetails: readTakeAwayOrderDetails,
  readDeliveryOrderDetails: readDeliveryOrderDetails,
  readLocallyOrderDetails: readLocallyOrderDetails,
  readArticleSearch: readArticleSearch,
  readCustomerSearchByFirstName: readCustomerSearchByFirstName,
  readCustomerSearchBySecondName: readCustomerSearchBySecondName,
  readBookingsByDate: readBookingsByDate,
  readBookingsByEmail: readBookingsByEmail,
  readBookingsById: readBookingsById,
  readAllCategories: readAllCategories,
  readCategoriesByLanguage: readCategoriesByLanguage,
  readCategoryDetails: readCategoryDetails,
  readAllSubcategories: readAllSubcategories,
  readSubcategoryDetails: readSubcategoryDetails,
  readSubcategoriesByLanguage: readSubcategoriesByLanguage,
  readAllTakeAwayOrders: readAllTakeAwayOrders,
  readTakeAwayOrdersByDate: readTakeAwayOrdersByDate,
  readTakeAwayOrdersByOrderNo: readTakeAwayOrdersByOrderNo,
  readAllDeliveryOrders: readAllDeliveryOrders,
  readDeliveryOrdersByDate: readDeliveryOrdersByDate,
  readDeliveryOrdersByOrderNo: readDeliveryOrdersByOrderNo,
  readLocallyOrdersByDate: readLocallyOrdersByDate,
  readLocallyOrdersByOrderNo: readLocallyOrdersByOrderNo,
  readDisabledDeliveryDays: readDisabledDeliveryDays,
  readDisabledTakeawayDays: readDisabledTakeawayDays,
  readDisabledBookingDays: readDisabledBookingDays,
  readAllDeliverySettings: readAllDeliverySettings,
  readAllLocallyOrders: readAllLocallyOrders,
  readAllBookings: readAllBookings,
  readReservedBookings: readReservedBookings,
  readAllSettings: readAllSettings,
  readAllPlans: readAllPlans,
  updateCustomer: updateCustomer,
  updateSubscription: updateSubscription,
  updateTable: updateTable,
  updateRingStaff: updateRingStaff,
  updateArticle: updateArticle,
  updateCategory: updateCategory,
  updateSubcategory: updateSubcategory,
  updateTakeAwayOrder: updateTakeAwayOrder,
  updateDeliveryOrder: updateDeliveryOrder,
  updateDeliverySettings: updateDeliverySettings,
  updateTakeAwayTime: updateTakeAwayTime,
  updateDeliveryTime: updateDeliveryTime,
  updateTakeawayTime: updateTakeawayTime,
  updateBookingTime: updateBookingTime,
  updateLocallyOrder: updateLocallyOrder,
  updateBooking: updateBooking,
  updateSetting: updateSetting,
  updateStatistic: updateStatistic,
  deleteCustomer: deleteCustomer,
  deleteTable: deleteTable,
  deleteRingStaff: deleteRingStaff,
  deleteAllRingStaffEntries: deleteAllRingStaffEntries,
  deleteArticle: deleteArticle,
  deleteCategory: deleteCategory,
  deleteSubcategory: deleteSubcategory,
  deleteTakeAwayOrder: deleteTakeAwayOrder,
  deleteAllTakeAwayOrders: deleteAllTakeAwayOrders,
  deleteDeliveryOrder: deleteDeliveryOrder,
  deleteAllDeliveryOrders: deleteAllDeliveryOrders,
  deleteDisabledDeliveryDay: deleteDisabledDeliveryDay,
  deleteDisabledTakeawayDay: deleteDisabledTakeawayDay,
  deleteDisabledBookingDay: deleteDisabledBookingDay,
  deleteOldTakeAwayOrders: deleteOldTakeAwayOrders,
  deleteOldDeliveryOrders: deleteOldDeliveryOrders,
  deleteOldLocallyOrders: deleteOldLocallyOrders,
  deleteOldBookings: deleteOldBookings,
  deleteOldRingStaff: deleteOldRingStaff,
  deleteLocallyOrder: deleteLocallyOrder,
  deleteAllLocallyOrders: deleteAllLocallyOrders,
  deleteBooking: deleteBooking,
  deleteAllBookings: deleteAllBookings,
  resetLocallyOrderStatistics: resetLocallyOrderStatistics,
  resetTakeAwayOrderStatistics: resetTakeAwayOrderStatistics,
  resetDeliveryOrderStatistics: resetDeliveryOrderStatistics,
  resetRingStaffStatistics: resetRingStaffStatistics,
  batchDeleteCustomer: batchDeleteCustomer,
  batchDeleteTakeAwayOrder: batchDeleteTakeAwayOrder,
  batchDeleteDeliveryOrder: batchDeleteDeliveryOrder,
  batchDeleteLocallyOrder: batchDeleteLocallyOrder,
  loadCustomer: loadCustomer
}
