import React, { useState, useEffect } from 'react'
import api from '../components/utils/api'
import { isLoggedIn } from '../services/auth'

const defaultState = {
  settings: () => ('')
}
export const SettingsContext = React.createContext(defaultState)

export const SettingsProvider = (props) => {
  const [settings, setSettings] = useState([])

  function isAuthenticated () {
    
    // console.log('is logged in:' + isLoggedIn())
    // console.log('settings:' + settings)
    
    if (isLoggedIn()) {
      if(!settings.length) {
        api.readAllSettings().then((settings) => {
          // console.log(settings)
          console.log('Settings loaded')
          if(settings && settings.length) { 
              setSettings(settings) 
          }
        })
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      isAuthenticated()
    }
  })

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {props.children}
    </SettingsContext.Provider>
  )
}
